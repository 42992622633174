import React from "react"
import FourFour from "../components/FourFour"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

export default function FourOhFour() {
  return (
    <Layout>
      <SEO title="Page not found" />
      <FourFour />
    </Layout>
  )
}
