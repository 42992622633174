import { Link } from "gatsby"
import React from "react"

export default function FourFour() {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="text-xl leading-6 text-london-darkred font-semibold tracking-wide uppercase">
            404
          </p>
          <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl sm:leading-10">
            Page not found
          </h1>
          <p className="mt-6 max-w-2xl text-2xl leading-7 text-gray-500 lg:mx-auto">
            Sorry - we couldn’t find what you were looking for.
          </p>
          <h3 className="mt-10 text-lg leading-8 font-extrabold tracking-tight text-gray-900 ">
            Need help finding something?
          </h3>
          <p className=" max-w-2xl text-lg leading-7 text-gray-500 lg:mx-auto">
            Email kadams@londonproperties.com and I'll help you.
          </p>
          <Link className="underline" to="/">
            Hompage
          </Link>
        </div>
      </div>
    </div>
  )
}
